#terms-and-conditions{
    h1{
        margin-bottom: 40px;
    }
    p{
        margin-bottom: 30px;
    }
    .tria-logo-terms{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        img{
            width: 216px
        }
    }
    .red-line{
        width: 100%;
        height: 5px;
        background-color: $tria-red;
        margin: 20px 0;
    }
}
