section#contact-us-awesome {
    @keyframes scaleInButton {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.2);
        }
    }
    @keyframes scaleOutButton {
        from {
            transform: scale(1.2);
        }
        to {
            transform: scale(1);
        }
    }
    position: relative;
    padding-top: 0px;
    margin-top: 0px;
    background: #4d9ff7;
    color: white;
    text-align: center;
    height: 800px;
    @include media-breakpoint-down(md) {
        height: 600px;
    }

    .contact-us-awesome-background {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .contact-us-awesome-foreground {
        @mixin scale($s) {
            transform: scale(#{$s});
            top: calc(50% - 0.5 * 150px * #{$s});
            left: calc(50% - 0.5 * 220px * #{$s});
        }

        position: absolute;
        @include scale(1.5);

        @media only screen and (max-width: 720px) {
            @include scale(1.1);
        }

        @media only screen and (max-width: 420px) {
            @include scale(0.9);
        }

        @media only screen and (max-width: 340px) {
            @include scale(0.7);
        }

        .contact-us-awesome-fg-images {
            position: absolute;

            .flamingo {
                position: absolute;
                -webkit-transform: scaleX(-0.13) scaleY(0.13);
                transform: scaleX(-0.13) scaleY(0.13);
                top: -460px;
                left: -168px;
            }

            .left-palm {
                position: absolute;
                transform: scale(0.28);
                top: -200px;
                left: 66px;
            }

            .right-palm {
                position: absolute;
                transform: scale(0.28);
                top: -250px;
                left: -180px;
            }
        }

        .contact-us-awesome-fg-text {
            position: absolute;
            width: 220px;

            div {
                .hero-logo {
                }

                h1 {
                    font-family: $passion-one-regular;
                    font-size: 55px;
                    margin: 0;
                }

                h3 {
                    font-family: $passion-one-regular;
                    font-size: 18px;
                    line-height: 0;
                    margin-left: 3px;
                    margin-bottom: 28px;
                }

                .contact-us-awesome-button {
                    background-color: $tria-red;
                    width: 150px;
                    height: 35px;
                    margin-bottom: 20px;

                    a {
                        color: #ffffff;
                        font-size: 16px;
                        font-family: "DINPro-Black", sans-serif;
                        margin-top: -4px;
                        text-decoration: none;

                    }
                }

                .contact-us-awesome-play {
                    width: 70px;
                    animation: scaleOutButton 0.2s ease-in-out 0s;
                    animation-fill-mode: forwards;


                    &:hover {
                        animation: scaleInButton 0.2s ease-in-out 0s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
    }
}

section#contact-us {
    position: relative;
    iframe {
        width: 100%;
    }
    .patern {
        width: 80%;
        position: absolute;
        left: 10%;
        z-index: 1;
        top: 20%;
        @include media-breakpoint-down(sm) {
            top: 40%;
        }
    }
    padding: 120px 20px 20px 20px;
    @include media-breakpoint-down(md) {
        padding: 20px 20px;
    }
    background-image: url("/images/ourWorkLightUpsideDown.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: 0px;
    // background-color: #0090FF;
    .contact-us-wrapper {
        z-index: 2;
        max-width: 475px;
        width: 100%;
        @include media-breakpoint-down(sm) {
            width: 80%;
        }
    }
    h2 {
        font-family: "DINPro-Light", sans-serif;
        letter-spacing: 75px;
        padding-left: 71px;
        font-weight: bold;
        color: white;
        margin-bottom: 5px;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    p {
        font-size: 19px;
        color: white;
    }
    p:nth-of-type(3) {
        margin-bottom: 10px;
    }
    .contact-us-form-img {
        margin-left: -11px;
        margin-bottom: 20px;
    }
    .captcha {
        div {
            div {
                div {
                    width: 100% !important;
                }
            }
        }
    }
    .footer-wrapper {
        margin-top: 60px;
    }
    .footer {
        font-family: 'DINPro-Light', sans-serif;
        color: white;
        font-size: 14px;
        margin-top: 8px;
        margin-left: 20px;

        a {
            color: #4E9FF7;
        }
    }
}
