body{
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    a, a:visited { outline: none; }
    button{ outline: none;}
}
.welcome-bg{
    display:none;
    @include media-breakpoint-down(sm){
        visibility: hidden;
        display:block;
        background-image: url('/assets/img/bg header -8.png');
        background-repeat: no-repeat;
        width: 100%;
        height: 300px;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center;
    }
}
html{
    scroll-behavior: smooth;
}
.bg-white{
    background-color: white;
}
::-webkit-scrollbar {
    display: none;
}
::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    background-color: white !important;
}
.absolute-bg{
    position: absolute;
    top: 0;
    width: 100%;
    background-color: transparent !important;

}
.submit-button{
    width: 100% !important;
    height: 50px !important;
    background-color: $tria-red !important;
    border-radius: 0 !important;
    outline: none !important;
    border: none !important;
    span{
        text-transform: uppercase;
        font-family: $din-pro-black;
        font-size: 22px;
        color: white;
        padding: 7px 7px;
        cursor: pointer;
    }
    @include media-breakpoint-down(sm){
        width: 120px;
        height: 30px;
        span{
            font-family: $din-pro-black;
            font-size: 18px;
            color: white;
            padding:5px;
            cursor: pointer;
        }
    }
}
.general-padding{
    @include media-breakpoint-up(lg){
        padding-left: 72px;
        padding-right: 72px;
    }
    @media only screen and (max-width: 1024px)  {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media only screen and (max-width: 768px)  {
        padding-left: 20px;
        padding-right: 20px;

    }
    @include media-breakpoint-down(sm){
        padding-left: 0;
    }
}
.general-padding-scribbling{
    @include media-breakpoint-up(lg){
        padding-left: 72px;
        padding-right: 72px;
    }
    @media only screen and (max-width: 1024px)  {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media only screen and (max-width: 768px)  {
        padding-left: 20px;
        padding-right: 20px;

    }
    @include media-breakpoint-down(sm){
        padding-left: 0;
        padding-right: 0;

    }
}
.tria-button{
    width: 180px;
    height: 40px;
    background-color: $tria-red;
    @include media-breakpoint-up(xl){
        width: 256px;
    }
    a{
        font-family: $din-pro-black;
        font-size: 18px;
        color: white;
        padding: 7px 7px;
        cursor: pointer;
            text-decoration: none;
    }
    @media only screen and (min-width: 1541px)  {
        width: 256px;
        height: 58px;
        a{
            font-family: $din-pro-black;
            font-size: 29px;
        }

    }
    @include media-breakpoint-up(md){
        opacity: 0.85;
    }
    &:hover{
        opacity: 1;
    }

    @include media-breakpoint-down(sm){
        width: 120px;
        height: 30px;
        a{
            font-family: $din-pro-black;
            font-size: 14px;
            color: white;
            padding:5px;
            cursor: pointer;
        }
    }
}

.usual-h1-p-group{
    h1{
        font-family:$passion-one-regular;
    }
    p{
        font-family: $din-pro-light;
        padding-bottom: 5px;
    }
    @media only screen and (min-width: 1542px){
        h1{
            font-size: 64px;
        }
        p{
            font-size: 24px;
        }
        h4{
            font-size: 38px;
        }
        .hero-h1{
            font-size: 96px;
        }
    }
}
.what-we-do-h1-p-group{
    h1{
        font-family:$passion-one-regular;
    }
    p{
        font-family: $din-pro-light;
        padding-bottom: 5px;
    }
    @media only screen and (min-width: 1542px){
        h1{
            font-size: 64px;
        }
        p{
            font-size: 22px;
        }
    }
}

h1{
    font-size: $h1;
    margin: 0;
    padding: 0;
    @include media-breakpoint-down(sm){
        font-size: $h3;
    }
}
h2{
    font-size: $h2;
    margin: 0;
    padding: 0;
    @include media-breakpoint-down(sm){
        font-size: $h4;
    }
}
h3{
    font-size: $h3;
    margin: 0;
    padding: 0;
    @include media-breakpoint-down(sm){
        font-size: $p;
    }
}
h4{
    font-size: 15px;
    margin: 0;
    padding: 0;
    @include media-breakpoint-down(sm){
        font-size: 14px;
    }
}
p{
    font-size: $p;
    margin: 0;
    padding: 0;
    @include media-breakpoint-down(sm){
        font-size: 12px;
    }
}

.container-no-padding{
    @include media-breakpoint-up(sm){
        padding: 0 !important;
    }
}