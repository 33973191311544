section#whatWeDo {
    @include media-breakpoint-up(md){
        background-image: url("/assets/img/bg 2.svg");
        background-color: #ffffff; /* Used if the image is unavailable */
        background-position: 120% 0%;
        background-repeat: no-repeat;
        background-size: 56%;
        padding: 20px 0;
    }
    @include media-breakpoint-down(sm){
        background-color: #F7F7F7;
    }

    .headline {
        padding-bottom: 30px;
        @include media-breakpoint-up(lg){
            padding: 20px 20px 30px 20px;
        }
        h1 {
            font-family: $passion-one-regular;
            margin-bottom: 15px;
            @include media-breakpoint-down(md) {
                margin-bottom: 5px;
            }
        }

        h4 {
            font-family: $passion-one-regular;

        }

        margin-bottom: 15px;
        @include media-breakpoint-down(md) {
            margin-bottom: 5px;
        }
        @media only screen and (min-width: 1542px){
            h1 {
              font-size: 94px;
            }

            h4 {
                font-family: $passion-one-regular;
                font-size: 34px;
            }
        }

    }
    .what-we-do-box{
        opacity: 0;
        margin-bottom: 30px;
        position: relative;
        button{
            position: absolute;
            bottom: 30px;
            width: 160px;
            @include media-breakpoint-up(xl) {
                width: 256px;
            }

        }


        @media only screen and (min-width: 1135px){
          height: 290px;
        }
        @media only screen and (min-width: 1542px){
            height: 379px;
        }
        @media only screen and (max-width: 1135px) and(min-width: 995px){
            height: 405px;
        }
        @media only screen and (max-width: 994px) and(min-width: 768px){
            height: 309px;
        }

        @include media-breakpoint-down(sm){
          height: 189px;
        }
        @include media-breakpoint-up(sm){
            padding: 20px 20px;
            background-color: rgba(255, 255, 255, 0.5);
        }
        h2{
            font-family: $passion-one-regular;
            padding-bottom: 10px;
        }
        p{
            padding-bottom: 10px;
        }
    }
}
