section#our-work-slider{
    margin-bottom: 149px;
    @include media-breakpoint-down(sm){
        margin-bottom: 0;
    }
    .hero-wrapper{
        position: relative;
        .overlay-div{
            background-color: black;
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0.5;
            z-index: 1;
            top: 0;
            left: 0;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 1080px;
        @include media-breakpoint-down(sm){
            height: 462px;

        }
        background-image:url('/assets/img/our work bg-8.png');
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center;
        .hero-text-wrapper{
            z-index: 2;
            color: white;
            @media only screen and (min-width: 1542px){
                transform: scale(1.5);

            }
            h1{
                font-family: $passion-one-regular;
                font-size: 80px;
                margin: 0;
                @include media-breakpoint-down(sm){
                    font-size: 70px;
                }
            }
            h2{
                font-family: "DINPro-Light", sans-serif;
                letter-spacing: 75px;
                padding-left: 71px;
                font-weight: bold;
                color: white;
                margin-bottom: 5px;
                @include media-breakpoint-down(sm){
                    letter-spacing: 53px;
                    padding-left: 12%;
                    padding-right: 12%;
                }
            }
            h3{
                font-family: $passion-one-regular;
            }
            button{
                margin-top: 20px;
                a{
                    font-family: "DINPro-Black", sans-serif;
                    font-size: 18px;
                    color: white;
                    padding: 7px 7px;
                    cursor: pointer;
                    @include media-breakpoint-down(sm){
                        font-size: 12px;
                    }
                }
            }
        }

    }
    .our-work-slider-component{
        display: flex;
        justify-content: center;
        position: absolute;
        width: 100%;
        top: 150px;
        @include media-breakpoint-down(sm){
            top:70px;
        }
        .our-work-wrapper:nth-of-type(1){
            margin-bottom: 20px;
        }
        .our-work-wrapper{
            position: relative;
            // width: 580px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .img-col{

                width: 100%;
                img{
                    max-width: 666px;
                }
            }
        }

    }
    .our-work-slider-component-bot{
        display: flex;
        justify-content: center;
        @include media-breakpoint-down(sm){
            display: none;
        }
        position: absolute;
        width: 100%;
        bottom: 150px;

        .our-work-wrapper{
            position: relative;
            // width: 580px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .img-col{

                width: 100%;
                img{
                    max-width: 666px;
                }
            }
        }

    }

}
