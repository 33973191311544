.testimonial-component {
    margin-top: 280px;
    @include media-breakpoint-down(md) {
        margin-bottom: 20px;
        margin-top: 50px;
    }
    .testimonial-col {
        padding: 0;
    }
    .owl-theme {
        .owl-dots {
            @include media-breakpoint-down(sm) {
                position: absolute;
                top: -32px;
                left: -9px;
            }
            .owl-dot {
                @include media-breakpoint-down(sm) {
                    span {
                        width: 60px;
                        border-radius: 1px;
                    }
                }
            }
            .active {
                span {
                    background: #018dfc;
                }
            }
            span {
                width: 80px !important;
                border-radius: 1px !important;
            }
        }
    }
    .testimonial-group {
        img {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            padding: 20px 79px;
        }

        h1 {
            font-family: $passion-one-regular;
            color: black;
            font-size: 36px;
        }

        h3 {
            font-family: $passion-one-regular;
            color: black;
            padding-left: 7px;
            font-size: 18px;
        }

        h3:nth-of-type(1) {
            @include media-breakpoint-up(sm) {
                padding-left: 22px;
            }
        }

        @media only screen and (min-width: 1541px) {
            h1 {
                font-size: 80px;
            }

            h3 {
                font-size: 56px;
            }
        }
    }
}
