section#our-work-hero {
    width: 100%;
    position: relative;
    height: 100%;
    .our-work-background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #310389;
    }
    .hero-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        position: relative;
        padding-top: 30px;
    }
        @include media-breakpoint-down(sm) {
            padding-top: 15px;
        
        }
        // background-image: url("/images/ourWorkPic@2x.png");
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center;
        
        .coins{
            position: absolute;
        }
        .coin-one{
            width: 8%;
            left: 25%;
            top: 70%;
            max-width: 90px;
            @include media-breakpoint-down(md){
                width: 6%;
                left: 25%;
                top: 60%;
            }
            @include media-breakpoint-down(sm){
                width:12%;
                left: 5%;
                top: 70%;
            }
        }
        .coin-two{
            width: 16%;
            right: 10%;
            top: 50%;
            max-width: 120px;
            @include media-breakpoint-down(md){
                width:10%;
                right: 10%;
                top: 50%;
            }
            @include media-breakpoint-down(sm){
                width:20%;
                right: -20px;
                top: 60%;
            }

        }
        .coin-three{
            width: 17%;
            left: 5%;
            top: 30%;
            max-width: 140px;
            @include media-breakpoint-down(md){
                width: 12%;
            left: 5%;
            top: 30%;
            max-width: 140px;
            }
            @include media-breakpoint-down(sm){
              display: none;
            }
        }
        .hero-text-wrapper {
            margin: 60px 0;
         
            // h1 {
            //     font-family: $passion-one-regular;
            //     font-size: 80px;
            //     margin: 0;
            //     @media only screen and (max-width: 1024px) {
            //         font-size: 55px;
            //     }

            //     @include media-breakpoint-down(sm) {
            //         font-size: 45px;
            //         margin: 0;
            //         padding: 0;
            //         line-height: 1;
            //     }
            // }
            // h2 {
            //     font-family: "DINPro-Light", sans-serif;
            //     letter-spacing: 75px;
            //     padding-left: 71px;
            //     font-weight: bold;
            //     color: white;
            //     margin-bottom: 5px;
            //     @include media-breakpoint-down(sm) {
            //         letter-spacing: 53px;
            //         padding-left: 12%;
            //         padding-right: 12%;
            //     }
            // }
            // h3 {
            //     font-size: 22px;
            //     font-family: $passion-one-regular;
            //     color: #ffd400;
            //     line-height: 0;
            //     @media only screen and (max-width: 1024px) {
            //         font-size: 16px;
            //         padding-left: 6px;
            //     }

            //     @include media-breakpoint-down(sm) {
            //         font-size: 12px;
            //     }
            // }
            img {
                border-style: none;
                max-height: 300px;
            }
            .hero-bar-wrapp {
                display: inline-flex;
                position: relative;
                max-width: 40%;
                margin: 50px 0;
                @media only screen and (max-width: 768px) {
                    margin: 20px 0;
                }
                .hero-bar {
                    width: 100%;
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }

                    height: 100%;
                }
            }
        }
    }

