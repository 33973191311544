section#hero-section{
    position: relative;
    background: #ad73b5;
    height: 800px;
    margin-top: 60px;
    margin-bottom: 120px;

    @media only screen and (max-width: 990px) {
        margin-top: 60px;
    }
    @media only screen and (max-width: 767.98px) {
        margin-top: 45px;
    }
    @media only screen and (max-width: 500px) {
        height: 600px;
        margin-bottom: 10px;
    }

    .hero-section-background {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .hero-section-foreground {
        position: absolute;
        width: 540px;
        top: calc(100% - 404px);
        left: calc(50% - 269px);
        transform: scale(1.1);

        @media only screen and (max-width: 800px) {
            top: calc(100% - 375px);
            transform: scale(1);
        }

        @media only screen and (max-width: 600px) {
            width: 360px;
            top: calc(100% - 410px);
            left: calc(50% - 180px);
            transform: scale(1.1);
        }

        @media only screen and (max-width: 500px) {
            width: 360px;
            top: calc(100% - 322px);
            left: calc(50% - 180px);
            transform: scale(0.85);
        }

        /* Let's keep it inside on Galaxy Fold */
        @media only screen and (max-width: 330px) {
            top: calc(100% - 350px);
            transform: scale(0.7);
        }

        div {
            h1 {
              font-family: $passion-one-regular;
              font-size: 49.4px;
              margin-left: -2px;
              margin-bottom: -4px;

              @media only screen and (max-width: 600px) {
                  margin-bottom: 8px;
                  font-size: 32px;
              }
            }

            p {
                font-family: $din-pro-light;
                font-size: 16px;
                color: #ffe4e4;
                padding-bottom: 5px;
                margin-bottom: 15px;
            }

            div {
                text-align: center;

                @media only screen and (max-width: 600px) {
                    text-align: left;
                }

                button {
                    background-color: $tria-red;
                    border-radius: 0px;
                    width: 200px;
                    height: 40px;
                    padding-bottom: 4px;

                    @media only screen and (max-width: 600px) {
                        border-radius: 0px;
                    }

                    a {
                        font-family: $din-pro-black;
                        font-size: 18px;
                        color: white;
                        padding: 7px 7px;
                        cursor: pointer;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
