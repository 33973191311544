#scribbling{
   
    margin-top: 90px;
    @include media-breakpoint-down(md){
        margin-top: 70px;
    }

    .tabs-header{
        display: flex;
        justify-content: flex-start;
        @include media-breakpoint-up(sm){
            margin-left: 35px;
        }
        .tabs-col-input{
            position: relative;
            input{
                outline:none;
                border: 1px solid black;
            }
            ::-webkit-input-placeholder { /* Edge */
                color: $grey-font-color;
                font-family: $passion-one-regular;
                font-size: $h4;
                opacity: 0.7;
                padding:15px 20px;
            }

            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $grey-font-color;
                font-family: $passion-one-regular;
                font-size: $h4;
                opacity: 0.7;
                padding:15px 20px;
            }

            ::placeholder {
                color: $grey-font-color;
                font-family: $passion-one-regular;
                font-size: $h4;
                opacity: 0.7;
                padding:15px 20px;
            }
            margin-right: 30px;
            width: 169px;
            height: auto;
            display: flex;
            justify-content: center;
            cursor: pointer;

            @include media-breakpoint-down(lg){
                ::-webkit-input-placeholder { /* Edge */
                    color: $grey-font-color;
                    font-family: $passion-one-regular;
                    font-size: 18px;
                    opacity: 0.7;
                    padding:10px
                }

                :-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $grey-font-color;
                    font-family: $passion-one-regular;
                    font-size: 18px;
                    opacity: 0.7;
                    padding:10px


                }

                ::placeholder {
                    color: $grey-font-color;
                    font-family: $passion-one-regular;
                    font-size: 18px;
                    opacity: 0.7;
                    padding:10px

                }
            }

            img{
                position: absolute;
                width: 27px;
                right: 6px;
                top: 8px;
                @include media-breakpoint-down(lg){
                        position: absolute;
                        width: 22px;
                        right: 0;
                        top: 3px;
                }
            }
        }
        .tabs-col{
            margin-right: 30px;
            border: 1px solid black;
            width: 169px;
            height: auto;
            display: flex;
            justify-content: center;
            cursor: pointer;
            a{
                font-family: $passion-one-regular;
                text-transform: uppercase;
                color:black;
                text-decoration: none;
                font-size: 24px;
                @media screen and (max-width: 1096px) {
                    font-size: 22px;
                }
                @include media-breakpoint-down(md){
                    font-size: 14px;
                }
                @include media-breakpoint-down(sm){
                    font-size: 16px;
                }
            }
            @include media-breakpoint-down(sm){
                max-width: 87px;
                margin-right: 10px;
            }
        }
        @include media-breakpoint-down(sm){
            .tabs-m-bot{
                margin-bottom: 15px;
            }
            .tabs-m-top{
                margin-top: 15px;
            }
        }

    }
    .tab-header-lg-md{
        @include media-breakpoint-down(sm){
            display: none;
        }
    }
    .tab-header-sm{
        .tabs-col-input{
            margin-top: 15px;
            display: block;
            img{
                position: absolute;
                width: 22px;
                right: 22px;
                top: 3px;
            }
            input{
                max-width: 150px;
            }
        ::-webkit-input-placeholder { /* Edge */
            color: $grey-font-color;
            font-family: $passion-one-regular;
            font-size: 18px;
            opacity: 0.7;
            padding:10px
        }

            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $grey-font-color;
                font-family: $passion-one-regular;
                font-size: 18px;
                opacity: 0.7;
                padding:10px


            }

            ::placeholder {
                color: $grey-font-color;
                font-family: $passion-one-regular;
                font-size: 18px;
                opacity: 0.7;
                padding:10px

            }


        }
        @include media-breakpoint-up(sm){
            display: none;
        }
    }
    .activeTab{
        background-color: #FFCE00;
    }
    .content-wrapper{
        margin-top: 30px;
        @include media-breakpoint-down(md){
            margin-top: 25px;
        }
        .scribbling-post-wrapper{
            // @include media-breakpoint-up(md){
            //     &:hover{background-color: #F3E1FB;}
            // }


            cursor: pointer;
           @include media-breakpoint-up(md){
               padding: 30px;
           }
            margin-bottom: 10px;
            .post-text{
                h1{
                    font-family: $passion-one-regular;
                }
                p{
                    font-family: $din-pro-light;
                }
                span{
                    font-family: $din-pro-black;
                    font-style: italic;
                    font-size: 12px;
                }
            }
            .post-img{
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                height: 200px;
                border-radius: 8px;
                margin: 20px 0;
                @include media-breakpoint-down(sm){
                    background-position: center;
                }
                
            }
        }

    }
}
.scribbling-bg{
    background-image: url("/images/scribb.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}