#awsome-component{

    color: white;
    text-align: center;
    h1 {
        font-family: $passion-one-regular;
        font-size: 80px;
        margin: 0;
        @media only screen and (max-width: 1024px) {
            font-size: 55px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 45px;
            margin: 0;
            padding: 0;
            line-height: 1;
        }
    }
    h2 {
        font-family: "DINPro-Light", sans-serif;
        letter-spacing: 75px;
        padding-left: 71px;
        font-weight: bold;
        color: white;
        margin-bottom: 5px;
        @include media-breakpoint-down(sm) {
            letter-spacing: 53px;
            padding-left: 12%;
            padding-right: 12%;
        }
    }
    h3 {
        font-size: 22px;
        font-family: 'VT323', monospace;
        color: #ffd400;
        line-height: 0;
        @media only screen and (max-width: 1024px) {
            font-size: 16px;
            padding-left: 6px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }
    .hero-text-inside-wrapp {
        position: relative;
        width: 40%;
        margin: 0 auto 40px auto;
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0;
        }
        .flamingo{
            position: absolute;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            max-width: 15%;
            top: -108px;
            left: 43%;
            @include media-breakpoint-down(lg) {
                right: 0;
                top: -83px;
            }
            @media only screen and (max-width: 768px) {
                right: -10px;
                top: -58px;
            }
           
          
            @include media-breakpoint-down(sm) {
                top: -63px;
                right: 3px;
            }
        
        }
        .flamingo-offset{
            position: absolute;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            max-width: 15%;
            top: -110px;
            left: 45%;
            @include media-breakpoint-down(lg) {
                right: 0;
                top: -83px;
            }
            @media only screen and (max-width: 768px) {
                right: -10px;
                top: -58px;
            }
            @include media-breakpoint-down(sm) {
                top: -63px;
                right: 3px;
            }
        
        }
        .left-palm {
            position: absolute;
            right: 16px;
            top: -79px;
            max-width: 21%;
            
            @include media-breakpoint-down(lg) {
                right: 0;
                top: -48px;
            }
            @media only screen and (max-width: 768px) {
                right: -10px;
                top: -36px;
            }
            @include media-breakpoint-down(sm) {
                top: -47px;
                right: 3px;
            }
        }
        .left-palm-offset {
            right: 25px;
            top: -70px;
            @include media-breakpoint-down(lg) {
                right: 0;
                top: -48px;
            }
            @media only screen and (max-width: 768px) {
                right: -10px;
                top: -36px;
            }
            @include media-breakpoint-down(sm) {
                top: -47px;
                right: 3px;
            }
        }
        .right-palm {
            position: absolute;
            left: -4px;
            top: -95px;
            max-width: 22%;
          
            @include media-breakpoint-down(lg) {
                left: -4px;
                top: -84px;
                max-width: 24%;
            }
            @media only screen and (max-width: 768px) {
                left: -18px;
                top: -50px;
                max-width: 24%;
            }
            @include media-breakpoint-down(sm) {
                top: -60px;
                left: -4px;
            }
        }
    }
    .hero-logo {
        max-width: 23%;
        width: 100%;
        height: 100%;
        @media only screen and (max-width: 1024px) {
            font-size: 22%;
        }
        @media only screen and (max-width: 768px) {
            max-width: 27%;
        }
       
        @include media-breakpoint-down(sm) {
            max-width: 60%;
        }
    }
}
// .scaleUp{

//     transform: scale(1.3);
    
// }