.come-in {
    transform: translateY(150px);
    animation: come-in 0.8s ease forwards;
    opacity: 0;
}
.come-in:nth-child(odd) {
    animation-duration: 0.6s; /* So they look staggered */
}

@keyframes come-in {
    to { transform: translateY(0);opacity: 1; }
}



.slide-in {
    @include media-breakpoint-up(sm){
        transform: translateX(150px);
    }
    animation: slide-in 0.8s ease forwards;
    opacity: 0;
}
.slide-in:nth-child(odd) {
    animation-duration: 0.6s; /* So they look staggered */
}

@keyframes slide-in {
    to { transform: translateX(0);opacity: 1; }
}

.come-in-fast {
    transform: translateY(150px);
    animation: come-in-fast 0.5s ease forwards;
    opacity: 0;
}
    //.come-in-fast:nth-child(odd) {
    //    animation-duration: 0.6s; /* So they look staggered */
    //}

@keyframes come-in-fast {
    to { transform: translateY(0);opacity: 1; }
}

@keyframes scale-in-animation {
    to{width: 100%;height: 550px;justify-content: center}
}
.scale-animation{
    animation: scale-in-animation 0.5s ease forwards;
}

