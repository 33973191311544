.accordion-wrapper{
    @include media-breakpoint-down(sm){
        margin-top: 15px;
    }
    .clickable-element{
        cursor: pointer;
    }
    video{
        border: none;
        width: 100%;
        height: auto;
    }
    .cover-image-service{
      
        width: 100%;
        height: 200px;
        background-size: 59%;       
         background-position: right;
        background-repeat: no-repeat;
        padding: 30px 100px;

        @include media-breakpoint-down(sm){
            width: 100%;
            height: 132px;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
            padding: 10px 20px;
        }
        @media only screen and (min-width: 2000px)  {
            height: 200px;
        }

        .overlay-text{
            text-align: left;
            color:white;
            h1{
                font-size: 70px;
                font-family: $passion-one-regular;
                text-transform: uppercase;
            }
            p{
                font-family: $din-pro-light;
            }
            @include media-breakpoint-down(sm){
                h1{font-size: 28px;}
            }
        }
    }
    .video-service-col{
        width: 20%;
        height: 100%;
        @include media-breakpoint-up(sm){
           height: 600px;
        }
    }
    .service-item-col{
        margin-bottom: 50px;
        .service-item{
            width: 100%;
            max-width: 480px;
            height:auto;
            @include media-breakpoint-down(md){
                height: 180px;
            }
            margin: 20px 0 0px 0 ;
            .service-item-contain{
                position: relative;
                .overlay-div-video{
                    width: 100%;
                    height: 100%;
                    background-color: black;
                    position: absolute;
                    z-index: 2;
                    opacity: 0.5;
                }
                .play-button{
                    width: 72px;
                    position: absolute;
                    top: 30%;
                    left: 39%;
                    fill: white;
                }
            }
            .service-item-img {
                width: 100%;
                height: auto;
                max-height: 250px;
            }
        }
        .service-item-info{
            color: white;
            display: inline-flex;
            align-items: baseline;
            position: absolute;
            width: 90%;
            bottom: -50px;
            @include media-breakpoint-down(sm){
                bottom: -25px;
            }
            span{
                font-family: $passion-one-regular;
                font-size: 21px;
                text-transform: uppercase;
                margin-right: 10px;
            }
            p{
                font-family: $passion-one-regular;
            }
            button{
                position: absolute;
                width: 42px;
                height: 22px;
                right: 0;
                background-color: $tria-red;
                color: white;
                outline:none;
                top: 5px;
                cursor:pointer;
                justify-content: center;
                display: flex;
                a{
                    font-size: 14px;
                    font-weight: bold;
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }
    .dialog-video-wrapper{
        video{
            width: 100%;
            height: auto;
        }
    }
    .dialog-img-wrapper{
        display: flex;
        justify-content: center;
        img{
            height: 50vh;
        }
    }
    // iframe{
    //     @include media-breakpoint-down(sm){
    //         max-height: 45%;
    //     }
    // }
}
