section#navigation{
    padding: 0;
    z-index: 3333;
    @include media-breakpoint-up(sm){
        .dropdown-item{
            padding: 5px !important;
        }
    }
    .dropdown-menu{
        border: none; 
       
    }
    .navbar{
        padding: 0;
        @include media-breakpoint-up(md){
            padding-left: 20px;
        }
        .navbar-brand{
            margin: 0;
            display: flex;
            align-items: center;
            .logo{
                width: 120px;
                @media only screen and (min-width: 1181px)  {
                    width: 212px;
                   }
            }
        }
        .navbar-toggler{
            border: none;
            padding-right: 0;
            padding-left: 28px;
            padding-top: 10px;
            .navbar-button-line{
                width: 40px;
                background-color: $tria-red;
                margin-bottom: 5px;
                height: 5px;
            }
        }
        #navbarSupportedContent{
            .navbar-nav{
            
                .first-nav-item{
                    margin-left: 90px;
                    @media only screen and (max-width: 1024px)  {
                     margin-left: 45px;
                    }
                    @media only screen and (max-width: 768px)  {
                        margin-left: 16px;
                    }
                    @media only screen and (max-width: 767px)  {
                        margin-left: 0;
                    }
                }
                .nav-item{
                    margin-right: 20px;
                    height: 45px;
                    &:hover{
                        color: #606060;
                        border-bottom: 5px solid #ffce00;
                    }
                }
                .active{
                    color:#606060 ;
                    border-bottom: 5px solid #FFCE00;
                }
                a{
                    padding-left: 0;
                    padding-right: 0;
                    font-size: 21px;
                    color:#606060;
                    font-family:$din-pro;
                    @media only screen and (max-width: 768px)  {
                        font-size: 12px;
                    }
                    @media only screen and (min-width: 991px) and (max-width: 1056px)  {
                    font-size: 18px;

                    }
                    @media only screen and (min-width: 1179PX) and (max-width: 1225px)  {
                        font-size: 18px;
    
                        }
                    @include media-breakpoint-down(sm){
                     font-size: $p;
                    }
                }
            }
        }
    }
}
.social-bar{
    padding: 20px 10px;
    @include media-breakpoint-down(sm){

    width: 100%;
    img{
        width: 30px;
    }
    }
    @media only screen and (min-width: 1181px)  {
    width: 160px;

    }
    width: 83px;
    display: flex;
    justify-content: space-around;
    img{
        width: 20px;
        vertical-align: top;
        border-style: none;
    }
}
