.cookie-wrapper {
    .el-checkbox {
        color: white;
    }
    color: black;
    position: fixed;
    bottom: 0;
    right: -100%;
    width: 100%;

    z-index: 222;
 background-color: rgba(#ffff, 0.9);
    padding: 10px 20px;
    .cookie-header {
        display: inline-flex;
        align-items: baseline;
        padding: 15px 0;
        @include media-breakpoint-down(sm) {
            align-items: center;

        }
        img{
            width: 45px;
             margin-right:10px;
        }
        h1 {
            font-family: 'VT323', monospace;
            @include media-breakpoint-down(sm) {
                font-size: 28px;
            }
        }
    }

    .cookie-text {
        padding-bottom: 15px;
        font-family: $din-pro-light;
        max-width: 100%;

    }

    .cooke-footer {
        
    }
    .submit-button-cookie {
        margin-left: 15px;
        width: 200px;
        color: white;
        height: 50px;
        background-color: $tria-red !important;
        border-radius: 15px !important;
        outline: none !important;
        border: none !important;
        span {
            text-transform: uppercase;
            font-family: $din-pro-black;
            font-size: 22px;
            color: white;
            padding: 7px 7px;
            cursor: pointer;
        }
        @include media-breakpoint-down(sm){
            width: 100%;
            margin: 0;
        }
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
        background-color:$tria-red;
        border-color: black;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label{
        color: black;
    }
}
