section#highlight {
    padding-top: 250px;
    @include media-breakpoint-down(lg) {
        padding-top: 125px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 0;

    }
    background-image: url("/images/grid@2x.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;

    .highlight-content-wrapper {
        @media only screen and (min-width: 1440px) {
            //padding: 0 200px;
        }
        .highlight-content-images {
            @media only screen and (min-width: 2050px) {
                display: flex;
                justify-content: center;
            }
            @media only screen and (max-width: 1542px) and(min-width: 2049px) {
                justify-content: end;
            }
            @media only screen and (min-width: 1135px) {
                margin-left: 7.3%;
            }
            display: initial;
        }
        .highlight-content {
            button {
                width: 160px;
                @include media-breakpoint-up(xl) {
                    width: 256px;
                }
            }
            @media only screen and (min-width: 1135px) {
                margin-left: 7.3%;
            }
            @media only screen and (min-width: 1440px) {
                margin-left: 4.3%;
            }
            @media only screen and (max-width: 1135px) and(min-width: 995px) {
                margin-left: 8.3%;
            }
            @media only screen and (max-width: 880px) and(min-width: 768px) {
                margin-left: 5%;
            }
            @include media-breakpoint-up(sm) {
                button {
                    margin-top: 30px;
                }
            }
            .text-section {
                @media only screen and (min-width: 1541px) {
                    h1 {
                        font-size: 68px;
                    }
                    p {
                        font-size: 21px;
                    }
                }
            }
        }
        .phone-section {
            .phone {
                opacity: 0;
            }
            .animate-float {
                opacity: 1 !important;
            }

            @keyframes floating {
                0% {
                    transform: translate(0, 0px);
                }
                50% {
                    transform: translate(0, 15px);
                }
                100% {
                    transform: translate(0, 0px);
                }
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }

            position: relative;

            img {
                width: 461px;
                height: 400px;
                position: absolute;
                @include media-breakpoint-down(md) {
                    width: 361px;
                    height: 300px;
                    position: absolute;
                }
            }

            img:nth-of-type(1) {
                top: -217px;
                left: -90px;
                animation-name: floating;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
                animation-delay: 150ms;
            }
            img:nth-of-type(3) {
                top: -113px;
                left: 59px;
                animation-name: floating;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
                animation-delay: 350ms;
            }

            img:nth-of-type(2) {
                top: 66px;
                left: 67px;
                animation-name: floating;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
                animation-delay: 550ms;
            }

            @include media-breakpoint-down(lg) {
                img:nth-of-type(1) {
                    top: -172px;
                    left: -50px;
                }
                img:nth-of-type(3) {
                    top: -113px;
                    left: 60px;
                }
                img:nth-of-type(2) {
                    top: 20px;
                    left: 57px;
                }
            }
        }

        .sm-phone-section {
            margin-left: -16px;
            @media only screen and (min-width: 768px) {
                display: none;
            }

            img {
                width: 105%;
                height: 100%;
            }
        }
    }

    .text-section {
        @include media-breakpoint-up(sm) {
            opacity: 0;
        }
        .highlight-span {
            font-size: $p;
            font-family: $din-pro-medium;
            padding-bottom: 0;
            color: $tria-red;
            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }
        }
    }
}
