section#singleScribbling{
    margin-top: 80px;
    margin-bottom: 30px;
    padding: 0 50px;
    @include media-breakpoint-down(lg){
        margin-top: 50px;
        padding:0 15px;
    }
    @include media-breakpoint-down(lg){
        margin-top: 50px;
        padding:0;
    }
    .back-arrow{
        cursor: pointer;
        width: 30px;
        margin-bottom: 30px;
    }
    h1{
        margin-bottom: 10px;
        border-bottom: 5px solid #FFCE00;
        font-family: $passion-one-regular;
    }
    img{
        margin-top: 10px;
        width: 100%;
        max-width: fit-content;
    }
    p{
        margin-top: 30px;
        font-family: $din-pro-light;
    }
    span{
        font-family:$din-pro-light;
        font-size: 12px;
    }
    span:nth-of-type(2){
        margin-right: 7px;
        font-family: $din-pro-black;
        font-style: italic;
        font-size: 12px;
    }
    span:nth-of-type(4){
        margin-right: 7px;
        font-family: $din-pro-black;
        font-style: italic;
        font-size: 12px;
    }
    span:nth-of-type(6){
        background-color: #FFCE00;
        font-family: $din-pro-black;
        border-radius: 5px;
        padding: 3px;
    }
    .post-content{
        font-family: $din-pro-light !important;
        font-size: 16px !important;
    }
}
